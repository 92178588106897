.portal-login {
  max-width: 500px;
  margin: 0 auto;
}

.portal-login h1 {
  text-align: center;
  margin-bottom: $spacing-medium;
}

.portal-login form {
  display: grid;
  grid-gap: $spacing-small;
}

.portal-login label {
  display: none;
}

.portal-login input {
  border: 1px solid $color-gray;
}

.portal-login button {
  @extend .btn;
  @extend .btn--primary;
  border: none;
  margin: 0 auto;
  padding: .5rem 2rem;
  cursor: pointer;
}

.portal-login__msg .error {
  color: red;
}
