.text-section-center {
  margin: 0 auto;
  max-width: 700px;
  text-align: center;
  margin-top: $spacing-medium;
}

.text-section-center h2 {
  font-weight: bold;
}

@include media-mobile {
  .text-section-center {
    margin-top: 0;
  }
}
