
/*
 * Card Component
 * */
.card {
  overflow: hidden;
  box-shadow: 4px 0 20px -5px rgba(0,0,0,.2);
  height: 100%;
}

.card > a:focus {
  border: 3px solid $color-secondary;
  outline: none;
}

.card img {
  width: 100%;
}

.card > div,
.card > a {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  position: relative;
}

.card__img {
  grid-row: 1 / 4;
  grid-column: 1 / 2;
}

.card__detail {
  grid-row: 1 / 4;
  grid-column: 1 / 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  position: relative;
  z-index: 2;
}

.card > a::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(to top, $color-primary, transparent, transparent);;
}

.card .card__img {
  overflow: hidden;
  margin-top: 2rem; // manully nudge the image to avoid top label cropping
}

.card .card__img img {
  transition: .3s ease;

}

.card:hover .card__img img {
  transform: scale(1.1);
}

.card__title {
  background: $color-primary;
  color: $color-white;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card__cta {
  grid-row: 3 / 4;
  grid-column: 1 / 2;
  color: $color-white;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1rem;
  padding: 1rem;
}

.card:hover .card__cta {
  color: $color-secondary;
  text-decoration: underline;
}

.card__logo-patent {
  max-width: 120px;
}

/* variants */

/* card specs */
.card--specs {

}

.card--specs:hover .card__img img {
  transform: none;
}

.card--specs > a::after {
  background: none;
}

.card--specs__files {
  position: relative;
  z-index: 10;
  color: $color-primary;
  padding: $spacing-medium;
}

.card--specs__files li {
  line-height: 1.5;
}

.card--specs__files li a {
  display: grid;
  grid-template-columns: 15px 1fr;
  grid-gap: $spacing-xsmall;
}

.card--specs__files li a:hover {
  text-decoration: underline;
}

.card--specs__files img {
  width: 15px;
}



@include media-mobile {
  .card__title {
    padding: 1rem;
    font-size: .85rem;
  }


  .card__cta {
    font-size: .85rem;
  }

  .card__logo-patent {
    max-width: 80px;
  }
}


/* one off cases */

// adjust card top label for card used in 2 columns layout
.col--2 .card__title {
  padding: 1.5rem 2rem;
}

@include media-tablet {
  .col--2 .card__title {
    padding: 1rem;
  }
}

/* end of one off cases */
