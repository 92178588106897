.text-hero {
  background: $color-gray;
}

.text-hero > * {
  @include container;
}

.text-hero p {
  max-width: 500px;
}
