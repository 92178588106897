@import "shared";

// Utility Classes


.container {
  @include container();
}

.container-inner {
  @include container-inner();
}

.flex-center {
  display: flex;
  justify-content: center;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-s {
  padding-bottom: $spacing-small;
}

.pb-m {
  padding-bottom: $spacing-medium;
}

.pb-l {
  padding-top: $spacing-large;
}

.pt-0 {
  padding-top: 0;
}

.pt-s {
  padding-top: $spacing-small;
}

.pt-m {
  padding-top: $spacing-medium;
}

.pt-l {
  padding-top: $spacing-large;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-s {
  padding-left: $spacing-small;
  padding-right: $spacing-small;
}

.px-m {
  padding-left: $spacing-medium;
  padding-right: $spacing-medium;
}

.px-l {
  padding-left: $spacing-large;
  padding-right: $spacing-large;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-xs {
  margin-bottom: $spacing-small / 2;
}

.mb-s {
  margin-bottom: $spacing-small;
}

.mb-m {
  margin-bottom: $spacing-medium;
}

.mb-l {
  margin-bottom: $spacing-large;
}

.mt-0 {
  margin-top: 0;
}

.mt-s {
  margin-top: $spacing-small;
}

.mt-m {
  margin-top: $spacing-medium;
}

.mt-l {
  margin-top: $spacing-large;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-s {
  margin-left: $spacing-small;
  margin-right: $spacing-small;
}

.mx-m {
  margin-left: $spacing-medium;
  margin-right: $spacing-medium;
}

.mx-l {
  margin-left: $spacing-large;
  margin-right: $spacing-large;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-s {
  margin-top: $spacing-small;
  margin-bottom: $spacing-small;
}

.my-m {
  margin-top: $spacing-medium;
  margin-bottom: $spacing-medium;
}

.my-l {
  margin-top: $spacing-large;
  margin-bottom: $spacing-large;
}



.text-center {
  text-align: center;
}

.bg-gray {
  background: $color-gray;
}

.bg-dark {
  background: $color-primary;
}

.color-white {
  color: $color-white;
}

// Responsive Column Layout
.col {
  display: grid;
  grid-gap: $spacing-medium;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;

  @media (max-width: 768px) {
    grid-gap: $spacing-small;
  }

  @for $i from 1 through 4 {
    &.col--#{$i} {
      grid-template-columns: repeat(#{$i}, 1fr);

      // @include media-desktop() {
      //   grid-template-columns: repeat(3, 1fr);
      // }

      @include media-tablet {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
      }

      @include media-mobile {
          grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

.section-title {
  h2 {
    margin: 0;
    margin-bottom: $spacing-large;
  }
}

.section-title--center {
  h2 {
    margin-top: $spacing-small;
    text-align: center;
  }
}

@include media-mobile {
  .section-title {
    h2 {
      margin-top: 0;
      margin-bottom: $spacing-small;
    }
  }
}


// Sections
// .section {
//   // padding-top: 2rem;
//   // padding-bottom: 2rem;
//   text-align: center;

//   & > *:not(.btn) {
//     text-align: left;
//   }

//   & > *:not(last-child) {
//     // margin-bottom: $spacing-medium;
//     padding-bottom: $spacing-medium;
//   }

//   &.-dark {
//     background-color: $color-primary;
//     color: $color-white;
//   }
// }

// // dotted grid
// .dotted-grid {
//   position: relative;

//   & > * {
//     z-index: 2;
//   }

//   &.-invert {
//     &::after {
//       filter: invert(1);
//     }
//   }

//   &::after {
//     content: url('/images/dots.svg');
//     display: block;
//     position: absolute;
//     z-index: -1;
//   }

//   &.-top-left {
//     &::after {
//       top: -2.5rem;
//       left: -7rem;
//     }
//   }

//   &.-top-right {
//     &::after {
//       top: -2.5rem;
//       right: -7rem;
//     }
//   }

//   &.-bottom-right {
//     &::after {
//       bottom: -2.5rem;
//       right: -7rem;
//     }
//   }

//   &.-bottom-left {
//     &::after {
//       bottom: -2.5rem;
//       left: -7rem;
//     }
//   }
// }


// /* Background */

// .bg-primary {
//   background-color: $background-color-primary;
// }

// .bg-secondary {
//   background-color: $background-color-secondary;
// }


// .object-fit-cover {
//   object-fit: cover;
//   width: 100%;
//   height: 100%;
// }


// // dotted blob in intro media object
// #contact-info,
// #introduction {
//   &.media-object {
//     figure {

//       position: relative;

//       & > * {
//         z-index: 2;
//       }

//       &::after {
//         content: url('/images/dots.svg');
//         display: block;
//         position: absolute;
//         z-index: -1;
//         bottom: -2.5rem;
//         left: -7rem;
//       }
//     }
//   }
// }

