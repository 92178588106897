@import 'shared';

.site-footer {
    background-color: $color-primary;
    color: $font-color-light;
    padding-top: 4rem;
    padding-bottom: 2rem;

    & > div {
      display: grid;
      grid-template-columns: 0.85fr 3fr;
      padding: 0;

      @include media-tablet() {
        display: block;
      }
    }

    h3 {
        font-family: $font-family-base;
        font-size: $font-size-medium;
        text-transform: uppercase;
    }

    .brand {
        margin-bottom: 2.5rem;
        display: block;

        img {
            max-width: 180px;
        }
    }

    nav {
        display: flex;
        justify-content: space-between;
        gap: $spacing-medium;

        @include media-tablet() {
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        ul {
            @include media-tablet() {
                width: 45%;
            }

            @include media-xs() {
                width: 100%;
            }
        }

        a {
            color: $font-color-light;
            font-weight: $font-weight-base;
            border-bottom: 2px solid transparent;

            &:hover,
            &.active {
                border-bottom-color: $color-secondary;
                color: $color-secondary;
            }
        }

        li {
            margin-bottom: $spacing-xsmall;

            @include media-tablet() {
                margin-bottom: .9rem;
            }
        }
    }

}

.site-footer .associations {
  grid-column: 2 / 3;
  padding-top: $spacing-large;
  display: flex;
  gap: $spacing-large;

  @include media-tablet() {
    flex-wrap: wrap;
  }

  ul {
    display: flex;
    gap: $spacing-medium;

    img {
      max-height: 28px;
    }

    li:nth-child(1) > img {min-width: 80px;}
  }
}



.bottom-footer {
    padding-top: $spacing-medium;
    margin-top: $spacing-medium;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $color-white;
    grid-column: 1 / 3;

    @include media-mobile() {
        display: block;
        text-align: center;
    }

    p,
    a {
        color: $font-color-light;
        margin: 0;
        font-size: $font-size-xsmall;

        @include media-mobile() {
            display: block;
            margin-bottom: $spacing-xsmall;

            &.made-by {
                display: inline-block;
            }
        }
    }

    a {
        border-bottom: 1px solid transparent;

        &:hover,
        &.active {
            border-bottom-color: $color-white;
        }
    }

    div {
        .made-by {
            color: $color-made-by;

            &:hover {
                border-bottom: none;
            }
        }
    }

    .legal {
      display: inline-block;

      @include media-mobile {
        display: block;
      }

      a {
        display: inline-block;
        margin-right: calc(2rem + 10px) !important;

        @include media-mobile {
          margin-right: 0 !important;
        }
      }
    }
}
