
/* Language Switcher */
.lang-switcher {
  position: relative;
  font-family: $font-family-heading;
}

.lang-switcher a {
  color: $color-white;
  border-bottom: 2px solid transparent;
}

.lang-switcher a:hover {
  color: $color-secondary;
  border-bottom: 2px solid $color-secondary;
}

.lang-switcher__toggle {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: .5rem;
  align-items: center;
  max-width: 60px;
  margin: 0 auto;
  cursor: pointer;

  span {
    border-bottom: 2px solid transparent;
  }
}

.lang-switcher__toggle--active,
.lang-switcher__toggle:hover {
  color: $color-secondary;

  span {
    border-bottom: 2px solid $color-secondary;
  }
}

.lang-switcher__items {
  display: none;
}

.lang-switcher__items--active {
  display: block;
}

.lang-switcher__items ul {
  position: absolute;
  top: 70px;
  right: 0;
  z-index: 10;
  background: $color-primary;
  padding: $spacing-small;
  display: grid;
  grid-template-columns: 1fr;
}

.lang-switcher__items li {
  display: flex;
  justify-content: space-between;
  margin-right: 0;
  gap: $spacing-medium;

  &:not(:last-child) {
    border-bottom: 1px solid $color-white;
    padding-bottom: $spacing-small;
    margin-bottom: $spacing-small;
  }
}

.lang-switcher__items li > span {
  display: flex;
  gap: $spacing-small / 2;
}


#lang_switcher_mobile {
  position: static;
  transform: initial;

  .lang-switcher__items {
    padding-left: 2rem;
    padding-right: 2rem;
    ul {
      position: static;
      transform: none;
      max-width: 400px;
      margin: 0 auto;
    }

    li {
      flex-direction: column;
    }

    span {
      justify-content: center;
    }
  }
}

@include media-tablet {
  .lang-switcher {
    display: none;
    font-size: 1.5rem;
  }

  .lang-switcher__toggle {
    max-width: 80px;
  }

  .lang-switcher--active {
    display: block;
    position: absolute;
    top: calc(50% + #{$spacing-medium});
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: 0;
    margin-right: 0;
  }

  .lang-switcher__items ul {
    right: initial;
    left: 50%;
    transform: translateX(-50%);
  }

}
