//-- Colors
$color-primary: #131E29;
$color-secondary: #F4AF00;
$color-white: #fff;
$color-gray: #EDEFF4;
$color-black: $color-primary;
$background-color-primary: $color-secondary;
$background-color-secondary: $color-gray;
$background-color-dark: $color-primary;

$color-legal-section: #151C27;
$color-made-by: #535b65;

$color-error: #d22b2b;

//-- Fonts
$font-family-base: 'Helvetica Neue', sans-serif;
// $font-family-heading: 'filson-pro', sans-serif;
$font-family-heading: "din-2014", sans-serif;

// Font Sizes
$font-size-base: 18px;

@function rem($size) {
	@return ($size / $font-size-base) * 1rem;
}

$font-size-xlarge: rem(60px);
$font-size-large: rem(40px);
$font-size-medium: rem(25px);
$font-size-smedium: rem(20px);
$font-size-regular: rem(18px);
$font-size-small: rem(16px);
$font-size-xsmall: rem(14px);

// Font Colors
$font-color-base: $color-primary;
$font-color-light: $color-white;

// Font Weights
$font-weight-base: 400;
$font-weight-medium: 500;
$font-weight-bold: 800;


//-- Buttons & Links
$button-border-weight: 1px;
$common-link-color: $color-primary;

// Primary Button
$button-base-font-color: $font-color-base;
$button-base-border-color: $font-color-base;
$button-base-hover-font-color: $color-white;
$button-base-hover-border-color: $font-color-base;

// Primary Button
$button-light-font-color: $color-white;
$button-light-border-color: $color-white;
$button-light-hover-font-color: $color-secondary;
$button-light-hover-border-color: $color-secondary;

@mixin button($type: 'defaut') {
	display: inline-block;
	line-height: 1;
	padding: $spacing-xsmall 1.2rem $spacing-xsmall 1.5rem;
	border-radius: $border-radius-common;
	align-items: center;
	font-family: $font-family-heading;
	font-size: $font-size-small;
	color: $button-base-font-color;
	border: $button-border-weight solid $button-base-border-color;
	text-decoration: none;
	cursor: pointer;
	position: relative;
	transition: $ui-transitions;
	background: transparent;
	background: linear-gradient(to left, transparent 50%, $color-primary 50%);
	background-size: 200% 100%;
	background-position: right bottom;

	&::after {
		content: url('../images/chevron.svg');
		vertical-align: middle;
		display: inline-block;
		height: 15px;
		width: 15px;
		color: $color-secondary;
		padding-left: $spacing-xsmall;
		transition: $ui-transitions;
	}

	&:focus,
	&:hover,
	&:active {
		background-position: left top;
		color: $button-base-hover-font-color;
		border-color: $button-base-hover-border-color;

		&::after {
			transform: translateX(5px);
		}
	}

	@if $type == 'link-style' {
		border-radius: 0;
		border: none;
		border-bottom: 2px solid $button-light-hover-border-color;
		color: $common-link-color;
		padding: 0 0 .3rem;
		background: none;

		&:focus,
		&:hover,
		&:active {
			color: $common-link-color;
		}
	}
}

//-- Media Queries
$media-xs: 480px;
$media-mobile: 767px;
$media-tablet: 998px;
$media-laptop: 1200px;
$media-large: 1440px;
$media-desktop: 1921px;
$media-max: 2560px;

$custom-with-you-section: 1110px;

@mixin media-custom-size($size) {
	@media screen and (max-width: $size) {
		@content;
	}
}

@mixin media-xs() {
	@media screen and (max-width: $media-xs) {
		@content;
	}
}

@mixin media-mobile() {
	@media screen and (max-width: $media-mobile) {
		@content;
	}
}

@mixin media-tablet() {
	@media screen and (max-width: $media-tablet) {
		@content;
	}
}

@mixin media-laptop() {
	@media screen and (max-width: $media-laptop) {
		@content;
	}
}

@mixin media-large() {
	@media screen and (max-width: $media-large) {
		@content;
	}
}

@mixin media-desktop() {
	@media screen and (max-width: $media-desktop) {
		@content;
	}
}

@mixin media-max() {
	@media screen and (min-width: $media-max) {
		@content;
	}
}

//-- Spacing
$spacing-xsmall: 0.5rem;
$spacing-small: 1rem;
$spacing-medium: 2rem;
$spacing-large: 4rem;
$spacing-huge: 7rem;
$spacing-insane: 10rem;
$spacing-ridiculous: 15rem;

@mixin default-paddingX() {
	padding-left: $spacing-huge;
	padding-right: $spacing-huge;

	@include media-large() {
		padding-left: $spacing-medium;
		padding-right: $spacing-medium;
	}

	@include media-mobile() {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
}

@mixin default-paddingY() {
	padding-top: $spacing-large;
	padding-bottom: $spacing-large;
}

@mixin default-marginX() {
	margin-left: $spacing-huge;
	margin-right: $spacing-huge;

	@include media-large() {
		margin-left: $spacing-medium;
		margin-right: $spacing-medium;
	}
}

@mixin container() {
  padding-top: $spacing-medium;
  padding-bottom: $spacing-medium;

  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding-left: $spacing-small;
  padding-right: $spacing-small;
}

@mixin container-inner() {
  padding-left: calc(calc(calc(100% - 1320px) / 2) - 7rem);
  padding-right: calc(calc(calc(100% - 1320px) / 2) - 7rem);

  @media (min-width: 1321px) {
    padding-left: $spacing-medium;
    padding-right: $spacing-medium;
  }

  @media (max-width: 1320px) {
    & > * {
      margin-left: $spacing-medium;
      margin-right: $spacing-medium;
    }
  }

  @media (max-width: 768px) {
      margin-left: $spacing-small;
      margin-right: $spacing-small;

      & > * {
        margin-left: $spacing-small;
        margin-right: $spacing-small;
      }
  }

}

// Common sizes
$header-height-desktop: 115px;
$header-height-mobile: 105px;

// Z-index
$zindex-menu: 50;
$zindex-popup: 10;
$zindex-max: 99999;

// Transitions
$ui-transitions: all 250ms ease-in-out;

// Borders
$border-radius-common: 20px;
$border-common: 2px solid $color-secondary;

// Box-shadows
$box-shadow-common: 4px 0px 20px -5px rgba(0, 0, 0, 0.2);

//-- Other mixins
@mixin flex-center() {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin absolute-center() {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin check-list() {
	margin-bottom: 1.5rem;
	font-size: $font-size-small;
	font-weight: $font-weight-bold;
	position: relative;
	padding-left: 3rem;

	&::before {
    content: '';
    background-image: url('../images/icon-check.svg');
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 30px;
    height: 30px;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    border-radius: 50%;
	}
}

@mixin buttonModel {
  border: 2px solid $color-primary;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  position: relative;

	transition: $ui-transitions;
	background: transparent;
	background: linear-gradient(to left, transparent 50%, $color-primary 50%);
	background-size: 200% 100%;
	background-position: right bottom;

  & > * {
    padding: $spacing-xsmall / 2 $spacing-small;
    // padding: 7px 10px 5px 10px;
    display: block;
  }


  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;


    // Manual pixel pushing: account for helvetica typeface bottom spacing in font source
    & > span {
      font-family: $font-family-heading;
    }
  }

  &:hover,
  &:active,
  &.-is-active,
  &.-is-selected {
    border: 2px solid $color-primary;
    color: $color-white;

		background-position: left top;

    &::before {
      transform: translateX(0);
    }

    a {
      color: $color-white;
    }
  }

}


h1, h2, h3 {
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  margin-bottom: $spacing-medium;
}

/* typeface patch for letter combo `fi` render broken `i` (switch typeface ligature off) */
h1, h2, h3, h4, h5, h6 {
  font-feature-settings: 'liga' off;
}

