.related-product {
  color: $color-white;

  &.mb-xs {
    margin-bottom: 0;
  }
}

.related-product li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.related-product .card {
  border: 1px solid rgba(225, 225, 225, .2);
}
