/*
 * media object
 * */
.media-object {
  display: grid;
  grid-gap: $spacing-large;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  align-items: center;
  margin-bottom: $spacing-large;

  :is(h1, h2, h3, h4, h5, h6, p) {
    margin: 0;
  }


  :is(h1, h2, h3, h4, h5, h6) {
    font-weight: 900;
    margin-bottom: $spacing-medium;
  }

  :is(h1, h2, h3, h4, h5, h6) + p {
    margin-bottom: 1rem;
  }

  video {
    width: 100%;
  }
}

.media-object__img figcaption {
  margin: $spacing-small / 2 0;
  font-size: .9rem;
}

.media-object__text {
  h1, h2 {
    font-size: rem(45px);
  }

  a:not(.btn) {
    &:hover {
      color: $color-secondary;
      text-decoration-line: underline;
      text-decoration-thickness: 2px;
    }
  }
}

/* reverse */
.media-object--reversed {
  & > *:nth-child(1) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  & > *:nth-child(2) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
}

/* variant */
.media-object--video {
  .media-object__img {
    z-index: 1;

    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(45deg, rgba($color-primary, .2), rgba($color-primary, .2));
      pointer-events: none;
    }

    &::after {
      content: '';
      background-image: url('/images/video-play-triangle-dark.svg');
      background-repeat: no-repeat;
      background-size: 20%;
      background-position: 30px center;
      background-color: $color-secondary;
      border-radius: 50%;
      position: absolute;
      display: block;
      width: 70px;
      height: 70px;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      pointer-events: none;
      transition: .3s ease;
    }
  }

  .media-object__img:hover::after {
    background-image: url('/images/video-play-triangle-light.svg');
    background-color: $color-primary;
  }
}

.media-object--slider {
  ._slider-wrapper {
    position: relative;
    overflow: hidden;
  }
}


.media-object--video-no-overlay figure {
  &::before {
    display: none;
  }
}

/* one off case variant for contact page */

.media-object--contact {
  line-height: 1.5;
}
.media-object--contact h3 {
  margin-bottom: 0;
  margin-bottom: $spacing-small / 2;
}

.media-object--contact a {
  display: inline-block;
}

.media-object--contact a[href^="tel:"]:not(:empty) {
    display: inline-flex;
    align-items: center;
    &::before {
      content: '';
      background-image: url('/images/icon-phone.svg');
      background-position: center;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      display: block;
      margin-right: $spacing-small / 2;
    }
  }

.media-object--contact a[href^="mailto:"]:not(:empty) {
    display: inline-flex;
    align-items: center;
    &::before {
      content: '';
      background-image: url('/images/icon-email.svg');
      background-position: center;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      display: block;
      margin-right: $spacing-small / 2;
    }
  }

.media-object--contact .media-object__text > * {
  margin-bottom: $spacing-medium;
}
/* end of one off case variant for contact page */


@include media-mobile () {

  .media-object {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: $spacing-small;
    margin-bottom: 0;

    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }


  .media-object--reversed {
    & > *:nth-child(1) {
      grid-column: initial;
      grid-row: initial;
    }

    & > *:nth-child(2) {
      grid-column: initial;
      grid-row: initial;
    }
  }

  .media-object__text h1,
  .media-object__text h2 {
    margin-bottom: $spacing-small;
  }

}
