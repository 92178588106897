.hero-product-single {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

.hero-product-single__text > * {
  margin-bottom: $spacing-medium;
}

.hero-product-single__title {

}

.hero-product-single__text h1 {
  font-weight: bold;
  font-size: rem(45px);
}

.hero-product-single__text h4 {
  font-weight: bold;
  text-transform: uppercase;
}


.hero-product-single__features li {
  line-height: 1.5;
  margin-left: $spacing-small * 0.7;
}

.hero-product-single__text li::before {
  content: '- ';
  margin-left: -$spacing-small * 0.7;
}

.hero-product-single__files {
  position: relative;
  z-index: 10;
  color: $color-primary;
}

.hero-product-single__files li::before {
  content: '';
}

.hero-product-single__files li a {
  display: grid;
  grid-template-columns: 15px 1fr;
  grid-gap: $spacing-xsmall;
}

.hero-product-single__files img {
  width: 15px;
}

//variant
.hero-product-single--video {
  .hero-product-single__img {
    z-index: 1;

    figure {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(45deg, rgba($color-primary, .2), rgba($color-primary, .2));
        pointer-events: none;
      }
  
      &::after {
        content: '';
        background-image: url('/images/video-play-triangle-dark.svg');
        background-repeat: no-repeat;
        background-size: 20%;
        background-position: 30px center;
        background-color: $color-secondary;
        border-radius: 50%;
        position: absolute;
        display: block;
        width: 70px;
        height: 70px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        pointer-events: none;
        transition: .3s ease;
      }

      &:hover::after {
        background-image: url('/images/video-play-triangle-light.svg');
        background-color: $color-primary;
      }
    }
  }
}

@media (max-width: 768px) {
  .hero-product-single {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
  }

  .hero-product-single__text > *:last-child {
    margin-bottom: 0;
  }
}
