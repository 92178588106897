.video-player {
  position: relative;
  padding-top: 56.25%;

  & > iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}


// Based the codebase from card -video
.video-player-modal {

  height: fit-content;
  overflow: hidden;
  height: 100%;
  box-shadow: $box-shadow-common;

  & > a {
    display: grid;
    height: 100%;
    grid-template-rows: 230px auto;

    &:focus {
      border: 2px solid $color-primary;
    }
  }


  figure {
    overflow: hidden;
  }

  img {
    transition: $ui-transitions;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  h3 {
    @include media-tablet {
      font-size: 1.2rem;
    }
  }

  p {
    font-size: $font-size-small;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }

    ._info::after,
    ._title::after {
      transform: translateX(8px);
    }

    //ici

    .card-info::after {
      right: -$spacing-small / 2;
    }
  }

  ._title,
  .card-title {
    margin: 0;
    color: $font-color-base;

    &::after {
      display: none;
    }
  }

  ._subtitle,
  .card-subtitle {
    font-size: $font-size-small;
    margin: .3rem 0 0;
  }

  .card-info,
  ._info,
  ._content {
    padding: $spacing-small;
    position: relative;
    background: $color-white;

    display: grid;
    grid-template-columns: 1fr 25px;
    grid-template-rows: auto 1fr;

    & > * {
      grid-column: 1 / 2;
    }

    @include media-tablet() {
      padding: $spacing-small / 2;
    }

    &::after {
      content: '';
      background-image: url('../images/chevron-large.svg');
      background-repeat: no-repeat;
      display: block;
      position: absolute;
      height: 25px;
      width: 25px;
      top: 50%;
      transform: translateY(-50%);
      right: -$spacing-small * .8;
      transition: $ui-transitions;

      grid-column: 2 / 3;

      @include media-tablet() {
        right: $spacing-small / 2;
      }
    }
  }

  ._info,
  ._content {
    &::after {
      top: 1.3rem;
    }
  }


  & > a {
    grid-template-rows: auto auto;
  }

  figure {
    position: relative;

    &::after {
      content: '';
      background-image: url('/images/video-play.svg');
      position: absolute;;
      display: block;
      width: 100px;
      height: 100px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  ._cta {
    @include button('link-style');
  }

  ._content {
    ._excerpt {
      min-height: 55px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    &::after {
      display: none;
    }
  }

}

