.specs {
  padding: $spacing-large $spacing-small;
  padding-top: 0;
}

.specs h2 {
  font-weight: bold;
}

.specs figure {
  max-width: 700px;
}

/* specs layout */

.specs--col-1 .specs__media {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  grid-gap: $spacing-medium;
}

.specs__media > * {
  margin: 0 auto;
}

.specs--col-2 .specs__media {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
  grid-gap: $spacing-medium;
}

.specs--col-2 .specs__media > div:nth-child(2) {
  order: -1;
}


/* specs table */
.specs table {
  background: $color-gray;
  display: inline-block;
  padding: $spacing-small;
  font-family: $font-family-heading;
}

.specs th {
  font-weight: bold;
}

.specs tr  {
  border-bottom: 1px solid $color-black;
}

.specs tbody tr  {
  border-bottom: 1px solid $color-black;

  td:first-child {
    font-weight: bold;
  }

  &:last-child {
    border: none;
  }
}

.specs tr th,
.specs tr td  {
  border-right: 1px solid $color-black;
  padding: $spacing-xsmall;

  &:last-child {
    border: none;
  }
}

/* table mobile (work with table-mobile.js) */
.specs__media--mobile > * {
  display: block;
  width: 100%;
}

.specs .table--mobile {
  padding: 0;
  width: 100%;
  font-family: $font-family-heading;
}

.table--mobile tbody {
  display: block;
  width: 100%;
}

.table--mobile tr th,
.table--mobile tr td  {
  border-right: none;
}

.table--mobile tbody tr  {
  border-bottom: none;
}

.table--mobile tr {
  display: grid;
  grid-template-columns: 1fr;
}

.table--mobile thead {
  display: none;
}

.table--mobile tbody tr td:not(:first-child):before {
  content: attr(data-label);
  margin-right: $spacing-small;
  font-weight: bold;
}

.table--mobile tbody tr td:first-child {
  background: $color-black;
  color: $color-white;
}

.table--mobile tbody td:not(:first-child):not(:last-child) {
  border-bottom: 1px solid $color-black;
}
/* end of table mobile (work with table-mobile.js) */



@media (max-width: 768px) {

  // .specs {
  //   padding-top: 0;
  // }

  .specs--col-2 .specs__media {
    grid-template-columns: 1fr;
  }

  .specs--col-2 .specs__media > div:nth-child(2) {
    order: initial;
  }

}
