/*
 * text col
 * */

.text-col {
  background: $color-primary;
  color: $color-white;
  padding: $spacing-medium $spacing-large;
}

.text-col > div {
  @include container;
}

.text-col h2 {
  text-align: center;
  margin: 0;
  margin-bottom: $spacing-large;
}

.text-col h3 {
  text-transform: uppercase;
}

.text-col ul {
  display: grid;
  grid-gap: $spacing-medium;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  margin-left: auto;
  margin-right: auto;
  grid-gap: $spacing-large;
}

.text-col--2 ul {
  grid-template-columns: repeat(2, 1fr);
  max-width: 820px;
}

.text-col--3 ul {
  grid-template-columns: repeat(3, 1fr);
}

.text-col li {
  max-width: 350px;
}


@include media-mobile {

  .text-col {
    padding: 0;
  }

  .text-col h2 {
    margin-bottom: $spacing-medium;
    text-align: left;
  }

  .text-col ul {
    grid-gap: $spacing-medium;
  }

  .text-col--3 ul,
  .text-col--2 ul {
    grid-template-columns: repeat(1, 1fr);
  }

  .text-col li {
    max-width: initial;
  }
}

