@import "shared";

input,
textarea,
select {
    padding: $spacing-xsmall;
    width: 100%;
    background-color: $color-white;
    border-color: transparent;
}

// keep form input from zoom in on focus for iOS
form {
  button,
  input,
  textarea,
  select {
    font-size: 1rem;
    font-family: $font-family-base;
  }
}

label {
    display: block;
    margin-bottom: $spacing-xsmall;
}

.form-general {
  padding-top: $spacing-large;
  padding-bottom: $spacing-large;
}

.form-general > * {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
}


@include media-mobile {
  .form-general {
    padding-top: 0;
    padding-bottom: 0;
  }
}


// #form {

//     h2 {
//         margin-bottom: $spacing-medium;
//     }

//     button {
//         @include button();
//         display: flex;
//         margin: 0 0 0 auto;
//     }

//     .content {
//         background-color: $background-color-secondary;
//         padding: $spacing-large 20%;

//         @include media-tablet() {
//             padding: $spacing-medium;
//         }
//     }
// }


