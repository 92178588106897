/*
 * text grid
 * */
.text-grid {
  background: $color-gray;
  padding-top: $spacing-medium;
  padding-bottom: $spacing-medium;
}

.text-grid > div {
  @extend .container;
}

.text-grid h2 {
  margin: 0;
  margin-bottom: $spacing-medium;
  font-weight: 900;
}

.text-grid ul {
  @extend .col;
  @extend .col--2;
}

.text-grid li {
  max-width: 400px;
}

.text-grid li *:last-child {
  margin-bottom: 0;
}


@include media-mobile {

  .text-grid h2 {
    margin-bottom: 1.5rem;
  }

  .text-grid ul {
    grid-gap: 1.5rem;
  }
}
