.stats {
  background: $color-primary;
  color: $color-white;
  text-align: center;
  padding-top: $spacing-medium;
  padding-bottom: $spacing-medium;
}

.stats__count {
  font-family: $font-family-heading;
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: $spacing-small;
}

.stats__count::after {
  content: "+";
}

.stats p {
  max-width: 300px;
  margin: 0 auto;
}


@include media-mobile {

  .stats__count {
    font-size: 2rem;
  }

}
