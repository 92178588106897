
.text-banner {
	@include default-paddingY();
  $margin: calc(calc(calc(100% - 1320px) / 2));
  margin-left: $margin;
  margin-right: $margin;
	text-align: center;
	background-color: $background-color-secondary;

  @media (min-width: 1570px) {
    $margin: 7rem;
    margin-left: $margin;
    margin-right: $margin;
  }

  @media (max-width: 1320px) {
    $margin: $spacing-medium;
    margin-left: $margin;
    margin-right: $margin;
  }


  @include media-tablet {
    padding-top: $spacing-medium !important;
    padding-bottom: $spacing-medium !important;
    padding-left: $spacing-small !important;
    padding-right: $spacing-small !important;

    margin-left: $spacing-small !important;
    margin-right: $spacing-small !important;
  }

	h1 {
		@include media-mobile() {
			font-size: $font-size-large;
			margin-bottom: $spacing-medium;
		}
	}

	p {
		max-width: 420px;
		margin: 0 auto;
	}

	.content a {
		@include button();
		margin-top: $spacing-small;
	}
}
