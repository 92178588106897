.breadcrumbs {
  font-family: $font-family-heading;
}

.breadcrumbs a {
  margin-right: 1rem;
}

.breadcrumbs a > span {

  &:hover {
    color: $color-secondary;
    border-bottom: 2px solid $color-secondary;
  }
}

.breadcrumbs a::after {
  content: '';
  display: inline-block;
  width: 8px;
  height:8px;
  border-top: 2px solid $color-primary;
  border-right: 2px solid $color-primary;
  transform: rotate(45deg);
  margin-left: 1rem;
}

.breadcrumbs > *:last-child {
  color: $color-secondary;
}
