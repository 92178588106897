@import 'shared';
@import 'utils';


/*
 * Global Components
 * */
@import 'components/header';
@import 'components/footer';

/*
 * Components
 * note: contain components that is extracted to have as standalone component (reusable without outer wrapper / scope)
 * */
@import 'components/button';
@import 'components/media-object';
@import 'components/forms';
@import 'components/hero-home';
@import 'components/text-grid';
@import 'components/text-col';
@import 'components/text-hero';
@import 'components/text-section-center';
@import 'components/cta-banner';
@import 'components/specs';
@import 'components/card';
@import 'components/hero-product-single';
@import 'components/slider';
@import 'components/stats';
@import 'components/breadcrumbs';
@import 'components/video-player';
@import 'components/text-banner';
@import 'components/portal-login';
@import 'components/lang-switcher';
@import 'components/doc-toggle';
@import 'components/related-product';


//-- Fonts
// TODO - Change for proper liscenced fonts
@font-face {
	font-family: 'Helvetica Neue';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('../fonts/helvetica-neue.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('../fonts/helvetica-neue.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('../fonts/helvetica-neue.woff') format('woff'),
		/* Modern Browsers */
		url('../fonts/helvetica-neue.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('../fonts/helvetica-neue.svg') format('svg');
	/* Legacy iOS */
}

* {
	box-sizing: border-box;
}

html {
	font-size: $font-size-base;
	scroll-behavior: smooth;
	min-width: 350px;
}

body {
	font-family: $font-family-base;
	color: $font-color-base;
	font-weight: $font-weight-base;
	line-height: 1.1;
	min-width: 300px;

	&.freeze {
		overflow: hidden;
		width: 100%;
		height: 100%;
		position: fixed;
	}
}

main {
	@include media-mobile() {
		overflow: hidden;
	}
}

h1,
h2,
h3,
h4 {
	font-family: $font-family-heading;
	line-height: 1;
	margin-bottom: $spacing-small;

	@include media-mobile() {
		margin-bottom: $spacing-xsmall;
	}
}

h1 {
	font-size: $font-size-xlarge;

	@include media-mobile() {
		font-size: rem(50px);
		margin-bottom: $spacing-small;
	}
}

h2 {
	font-size: $font-size-large;
}

h3 {
	font-size: $font-size-medium;
}

h4 {
	font-size: rem(20px);
}

a {
	text-decoration: none;
	color: $common-link-color;
}

p {
	margin-bottom: $spacing-small;
	font-size: $font-size-regular;
	line-height: 1.5;

	@include media-mobile() {
		font-size: $font-size-regular;
	}
}

em,
strong {
	font-weight: $font-weight-bold;
}

nav ul {
	list-style-type: none;
}

span,
p {
	font-weight: $font-weight-base;
}

img {
	max-width: 100%;
	display: block;
	height: auto;
}

video {
  margin-bottom: -1px;
}

// main > section,
body > footer {
	@include default-paddingX();
}

body > header {
  $padding: calc(calc(calc(100% - 1320px) / 2));
  padding-left: $padding;
  padding-right: $padding;

  @media (min-width: 1570px) {
    $padding: 7rem;
    padding-left: $padding;
    padding-right: $padding;
  }

  @media (max-width: 1320px) {
    $padding: $spacing-medium;
    padding-left: $padding;
    padding-right: $padding;
  }

  @include media-mobile() {
    padding-left: $spacing-small;
    padding-right: $spacing-small;
  }
}

.chevrons {
	&::after {
		content: '';
		position: absolute;
		width: 450px;
		height: 230px;
		background: url('../images/chevron-group.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		right: $spacing-large;
		bottom: -100px;
    z-index: 2;
    pointer-events: none;

		@include media-tablet() {
			right: $spacing-small;
			width: 250px;
		}

		@include media-mobile() {
			right: $spacing-small;
			width: 150px;
		}
	}
}


.breadcrumb {
	margin-bottom: $spacing-medium;

  @include media-mobile {
    margin-bottom: $spacing-small;
  }

	li {
		display: inline-block;
		font-size: $font-size-small;

		a {
			text-decoration: none;
			border-bottom: 1px solid transparent;

			&:hover {
				border-bottom: 1px solid $font-color-base;
			}
		}

		&::after {
			content: url('../images/chevron-dark.svg');
			display: inline-block;
			height: 9px;
			width: 9px;
			margin: 0 .3rem 0 $spacing-xsmall;
		}

		&:last-of-type {
			font-weight: $font-weight-medium;

			&::after {
				display: none;
			}
		}
	}
}

.trademark {
  font-size: 1rem;
  display: inline-block;
  position: relative;
  top: -10px;

  &::after {
    content: 'TM';
    font-size: 10px;
    position: absolute;
    top: 0;
    right: -16px;
    font-weight: bold;
  }

  &.mc::after {
	content: 'MC';
  }
}

.patents #content,
#terms {
    @include container;
}

#quash-training {
	padding-block: $spacing-large;
	margin-bottom: 0;

	.btn {
		line-height: 1;
	}
}

//safari fix for helvetica render issue (all major browser have spacing withing type face but not safari)

@media not all and (min-resolution: 0.001dpcm) {
  .gallery-product ._model > span,
  .gallery-projects ._model > span,
  .hero.-product ._model a > span {
      position: relative;
      top: 0 !important;
  }
}
