.cta-banner {
  background-size: 50%;
  background-position: center right;
  background-repeat: no-repeat;
  position: relative;
  padding-top: $spacing-large;
  padding-bottom: $spacing-large;
}

.cta-banner::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to right, $color-gray, $color-gray 50%, transparent);
}

.cta-banner__text {
  position: relative;
  z-index: 2;
}

.cta-banner h2 {
  font-weight: 900;
  margin-bottom: $spacing-medium;
}

@media (max-width: 1024px) {
  .cta-banner {
    background-size: 60%;

  padding-top: $spacing-small;
  padding-bottom: $spacing-small;
  }
}

@media (max-width: 960px) {
  .cta-banner {
    background-size: 65%;
  }
}

@media (max-width: 820px) {
  .cta-banner {
    background-size: 70%;
  }

  .cta-banner::after {
    background: linear-gradient(to right, $color-gray, $color-gray 30%, transparent);
  }
}

@media (max-width: 560px) {
  .cta-banner {
    background-size: cover;
    background-position: center;
    padding-top: $spacing-large * 2;
    padding-bottom: 0;
  }

  .cta-banner::after {
    background: linear-gradient(to top, $color-gray, $color-gray 40%, transparent, transparent);
  }

  .cta-banner h2 {
    margin-bottom: 1rem;
  }

  .cta-banner__text {
    max-width: 400px;
    margin: 0 auto;
  }
}
