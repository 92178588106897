@import 'shared';

#site-switcher {
  @include container-inner();
  width: 100%;
  background-color: $color-primary;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid rgba(255,255,255,0.2);

  @include media-mobile() {
    margin-inline: 0;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 190px;

    @include media-mobile() {
      width: 165px;
    }
  }

  li {
    height: 100%;
    display: flex;
    align-items: center;

    &:last-of-type {
      position: relative;

      &::after {
        width: 100%;
        height: 1px;
        background-color: #FFF;
        position: absolute;
        left: 0;
        bottom: -1px;
        content: "";
      }
    }
  }

  a {
    padding-block: .5rem;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .moovop-logo {
    width: 70px;
    height: auto;

    @include media-mobile() {
      width: 60px;
    }
  }

  .versilis-logo {
    width: 87px;
    height: auto;

    @include media-mobile() {
      width: 74px;
    }
  }
}

.site-header {
    @include container-inner();
    padding: $spacing-medium 0;
    width: 100%;
    background: $color-primary;
    color: $color-white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @include media-tablet() {
      margin: 0 !important;
      padding: $spacing-small 0;
    }

    .brand {
        display: block;
        max-width: 160px;
        width: 100%;

        @include media-tablet() {
            width: 100px;
        }
    }



    .burger-menu {
        display: none;
        position: absolute;
        top: 2.7rem;
        right: $spacing-small;
        width: 25px;
        height: 24px;
        cursor: pointer;
        z-index: $zindex-max;
        margin-right: 0;

        @include media-tablet() {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-end;
            right: $spacing-small * 2;
        }

        @include media-mobile() {
          right: $spacing-small;
          top: 2.5rem;
        }

        span {
            display: block;
            height: 3px;
            width: 100%;
            background: $color-white;
            opacity: 1;
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;
        }

        &.active {
            top: 2.7rem;
            
            @include media-mobile() {
              top: 2.5rem;
            }

            span:nth-child(1) {
                transform: translateY(10px) rotate(45deg);
            }

            span:nth-child(2) {
                transform: scale(0);
            }

            span:nth-child(3) {
                transform: translateY(-6px) rotate(-45deg);
            }
        }
    }

  .lang { text-transform: uppercase; }
}

.main-nav {

  a {
    font-family: $font-family-heading;
    color: $color-white;
    padding: 0 0 4px 0;
    border-bottom: 2px solid transparent;
    transition: 0.3s ease;

    &.current-page,
    &:hover {
      padding: 0 0 6px 0;
      border-color: $color-secondary;
      color: $color-secondary;
    }
  }

  li {
    margin-right: $spacing-medium;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .desktop-hide {
    display: none;

    @include media-tablet() {
      display: block;
    }
  }
}

.main-nav ul {
  display: flex;
}



@include media-tablet {
  .main-nav ul {
    display: none;
  }

  .main-nav li {
    margin-right: 0;
  }

  .main-nav li a {
    text-transform: uppercase;
    font-size: 1.5rem;
  }

  .main-nav--active {
    display: flex;
    flex-direction: column;
    gap: $spacing-small;
    background: $color-primary;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding-top: $spacing-large;
  }

  .main-nav--active ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $spacing-small;
    text-align: center;
  }
}


@media (orientation: landscape) {
  .main-nav--active {
    padding-top: 0;
  }
}
