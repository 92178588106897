.doc-toggle {
  display: none;
}

.doc-toggle--active {
  display: block;
}

.toggle-nav {
  display: flex;
  gap: $spacing-medium;
}

.toggle-nav li {
  cursor: pointer;
}

.toggle-nav--active {
  color: $color-secondary;
  border-bottom: 1px solid $color-secondary
}
