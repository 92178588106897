@import 'shared';


.hero-home {
  position: relative;
  display: flex;
  height: 85vh;
  align-items: center;
  overflow: hidden;
  background: $color-primary;
  margin-top: -5px;

  &::before {
    content: '';
    background: linear-gradient(166deg, $color-primary,$color-primary 28%, transparent 50%);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    opacity: 1;
    transform: scale(1.1);
    margin-top: -5px;
    pointer-events: none;
  }



  ._video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }

  ._content {
    position: relative;
    z-index: 10;
    padding-left: calc(calc(calc(100% - 1320px) / 2) - 2rem);
    color: $color-white;

    margin: $spacing-medium;

    @include media-mobile() {
      padding: $spacing-small;
      padding-bottom: $spacing-small;
      margin-left: 0;
    }

    p {
      max-width: 400px;
    }
  }
}

@media (max-width: 560px) {
  .hero-home {
    display: block;
    height: initial;

    &::before {
      background: transparent;
      box-shadow: inset 0rem 4rem 30px $color-primary;
    }


    h1 {
      font-size: 1.5rem;
    }

    p {
      font-size: .9rem;
    }

    ._video {
      height: initial;
      transform: none;
      position: static;
      position: initial;
      width: 100%;
    }

    ._content {
      position: relative;
      z-index: 1;
      background: $color-primary;
      width: 100%;
      margin: 0;
      padding: $spacing-medium $spacing-small;
      box-shadow: 0px -35px 20px 5px $color-primary;
    }
  }

}


