.slider { overflow: hidden; }

.slider figcaption {
  text-align: left;
  padding: $spacing-xsmall 0;
  text-transform: uppercase;
}

.slider p {
  line-height: 1;
}

.slide-progress {
  width: 200px;
  height: 2px;
  display: block;
  margin: 0 auto;
  background: $color-gray;
  margin-top: $spacing-small;
  // margin-bottom: $spacing-small;
  position: relative;

  .slide-progress__current {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    display: block;
    background: $color-secondary;
  }
}


.glide__arrow.glide__arrow--disabled {
  display: none;
}

.glide__arrow {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  border: none;
  height: 100%;
  width: 40px;
  background: transparent;
  cursor: pointer;
  color: #fff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s ease;
}

.glide__arrow img {
  width: 15px;
}

.glide__arrow--prev {
  left: -35px;

  &::after {
    // content: '';
    // display: block;
    // width: 15px;
    // height: 15px;
    // border-top: 2px solid $color-black;
    // border-left: 2px solid $color-black;
    // transform: rotate(-45deg);
  }
}

.glide__arrow--next {
  right: -35px;

  &::after {
    // content: '';
    // display: block;
    // width: 15px;
    // height: 15px;
    // border-top: 2px solid $color-black;
    // border-right: 2px solid $color-black;
    // transform: rotate(45deg);
  }
}

.bg-dark .glide__arrow--prev {
  top: 40%;

  &::after {
    border-top: 2px solid $color-white;
    border-left: 2px solid $color-white;
  }
}

.bg-dark .glide__arrow--next {
  top: 40%;

  &::after {
    border-top: 2px solid $color-white;
    border-right: 2px solid $color-white;
  }
}

@media (max-width: 1360px) {

  .glide__arrow img {
    width: 12px;
  }

  .glide__arrow--prev {
    left: -30px;

    // &::after {
    //   width: 12px;
    //   height: 12px;
    // }
  }

  .glide__arrow--next {
    right: -30px;

    // &::after {
    //   width: 12px;
    //   height: 12px;
    // }
  }
}

@media (max-width: 768px) {
  .slider .container {
    // padding-bottom: 0;
  }

  .bg-dark .slide-progress {
    margin-top: -.5rem;
  }
}

.product-entry .glide {
  padding-inline: $spacing-small * 1.25;

  .glide__arrow--disabled {
    display: flex;
    opacity: 0;
  }

  .glide__arrow--prev {
    left: -15px;
  }

  .glide__arrow--next {
    right: -15px;
  }
}