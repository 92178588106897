/*
 * button
 * */

/* base */
.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  padding: .7rem 2rem .65rem 2rem;
  transition: .3s ease;
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.btn:hover {
  background: #333;
}

/* variant */
.btn--primary {
  background: $color-secondary;
  color: $color-primary;
}

.btn--primary:hover {
  background: $color-primary;
  color: $color-white;
}

.btn--secondary {
  background: $color-primary;
  color: $color-white;
}

.btn--secondary:hover {
  background: $color-secondary;
  color: $color-white;
}

